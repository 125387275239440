@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --foreground-rgb: 0, 0, 0;
  --color1-rgb: 144, 193, 233;
  /* #90C1E9 */
  --color2-rgb: 234, 82, 71;
  /* #EA5247 */
  --color3-rgb: 238, 115, 48;
  /* #EE7330 */
  --color4-rgb: 253, 220, 53;
  /* #FDDC35 */
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --color1-rgb: 144, 193, 233;
    /* #90C1E9 */
    --color2-rgb: 234, 82, 71;
    /* #EA5247 */
    --color3-rgb: 238, 115, 48;
    /* #EE7330 */
    --color4-rgb: 253, 220, 53;
    /* #FDDC35 */
  }
}

@media (prefers-color-scheme: light) {
  html {
    color-scheme: dark;
  }
}

body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(to top left,
      rgb(var(--color1-rgb)),
      rgb(var(--color2-rgb)),
      rgb(var(--color3-rgb)),
      rgb(var(--color4-rgb)));
  background-size: 200% 200%;
  animation: gradient-animation 10s ease infinite;
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@layer base {
  h1 {
    @apply text-5xl font-extrabold mb-8 text-center text-white drop-shadow-lg;
  }
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }

  .gradient-background {
    background: linear-gradient(to top left,
        rgb(var(--color1-rgb)),
        rgb(var(--color2-rgb)),
        rgb(var(--color3-rgb)),
        rgb(var(--color4-rgb)));
    background-size: 200% 200%;
    animation: gradient-animation 10s ease infinite;
  }
}